<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        What is the energy of an electron (in J) in the ground state of the hydrogen atom?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{E:}$"
        append-text="$\text{J}$"
        show-helpful-hint
        helpful-hint-position="below"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        Now let us consider the excited state of hydrogen. What is the energy of an electron (in J)
        in the
        <stemble-latex content="$n$" />
        =
        <number-value :value="energyLevel" />
        level?
      </p>

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{E:}$"
        append-text="$\text{J}$"
        show-helpful-hint
        helpful-hint-position="below"
        class="mb-2"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question15',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    energyLevel() {
      return this.taskState.getValueBySymbol('energyLevel').content;
    },
  },
};
</script>
